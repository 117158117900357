<template>
  <c-box w="100%" maxWidth="590px">
    <c-form-control marginBottom="80px">
      <c-form-label
        fontSize="14px"
        color="#555555"
        fontWeigh="400"
        fontFamily="Roboto"
      >
        Saran Gizi dan Gaya Hidup
      </c-form-label>
      <!-- <c-input-group size="md"> -->
        <textarea
          rows="3"
          v-chakra
          id="address"
          placeholder="Masukkan Saran Gizi dan Gaya Hidup"
          d="block"
          border="1px solid #E2E8F0"
          border-radius="md"
          minHeight="150px"
          type="text"
          lineHeight="20px"
          p="10px"
          w="100%"
          v-model="value_.advice"
        />
      <!-- </c-input-group> -->
    </c-form-control>


    <c-form-control marginBottom="30px" display="flex">
      <c-button
        backgroundColor="superLightGray.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="darkGray.900"
        marginRight="20px"
        as="router-link"
        :to="{name:'nutri.profile-gizi'}"
        marginBottom="20px"
      >
        Batal
      </c-button>
      <c-button
        backgroundColor="brand.900"
        px="26px"
        fontSize="18px"
        fontWeight="500"
        py="26px"
        borderRadius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "EditNutritionAdviceForm",
  props: ["value"],
  data() {
    return {
      value_: this.value,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) { this.value_ = val; }
    },
    value_(val) {
      this.$emit("input", val);
    }
  },
};
</script>
